@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

:root {
  --primary: rgba(255, 0, 131, 1);
  --secondary: rgba(236, 72, 153, 1);
}

body {
  background-image: url('./assets/images/token.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  font-family: 'Space Grotesk', sans-serif;
}

.custom-placeholder::placeholder {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  color: #ececec;
}

body,
html {
  height: 100%;
  overflow-y: auto;
}

main {
  min-height: 100vh;
  flex-grow: 1;
  padding: 1rem;
  background: url('./assets//images/bg.png');
  background-repeat: no-repeat;
  background-position-x: left;
}

.spinner-border {
  width: 1rem;
  height: 1rem;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

/* Remove up and down arrows from number input */
.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-arrows {
  -moz-appearance: textfield;
}

.solana-wallet-wrapper .custom-solana-button {
  background-color: #EC4899 !important;
  color: black !important;
  font-size: 16px !important;
  height: 44px !important;
  border-radius: 8px !important;
  width: 100% !important;
  justify-content: center !important;
  font-weight: 300 !important;
}

.solana-wallet-wrapper .custom-solana-button:hover {
  background-color: #D53F8C !important;
}
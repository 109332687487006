@font-face {
  font-family: 'Liberation Mono';
  src: url('./assets/fonts/liberation-mono/LiberationMono-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Liberation Mono';
  src: url('./assets/fonts/liberation-mono/LiberationMono-Bold.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'Liberation Mono';
  src: url('./assets/fonts/liberation-mono/LiberationMono-Italic.ttf') format('truetype');
  font-style: italic;
}
@font-face {
  font-family: 'Liberation Mono';
  src: url('./assets/fonts/liberation-mono/LiberationMono-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Liberation Sans';
  src: url('./assets/fonts/liberation-sans/LiberationSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Liberation Sans';
  src: url('./assets/fonts/liberation-sans/LiberationSans-Bold.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'Liberation Sans';
  src: url('./assets/fonts/liberation-sans/LiberationSans-Italic.ttf') format('truetype');
  font-style: italic;
}
@font-face {
  font-family: 'Liberation Sans';
  src: url('./assets/fonts/liberation-sans/LiberationSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-select-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  background-color: #151515;
  border: 1px solid #343434;
  border-radius: 8px;
}

.custom-select {
  appearance: none;
  background: none;
  border: none;
  color: white;
  padding: 0 1em;
  width: 100%;
  height: 100%;
  font-size: 1em;
  cursor: pointer;
}

.custom-select option {
  color: rgb(253, 253, 253);
  background-color: #151515;
}

.dropdown-icon {
  position: absolute;
  right: 10px;
  pointer-events: none;
}

.form-container-shadow {
  box-shadow: 0px 16px 128px 0px rgba(99, 54, 144, 0.2);
}

.transition-max-height {
  transition: max-height 0.5s ease-in-out;
}

.find-redeem-button {
  color: #fff; /* Text color */
  border: 1px solid #EC4899; /* Border color */
  border-radius: 4px; /* Rounded corners */
  margin-right: 10px; /* Space between button and settings icon */
  cursor: pointer;
  font-weight: 400; /* Optional: Make text bold */
  font-size: 14px;
}


/* Custom scrollbar styles */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #343434 #151515;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #151515;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #343434;
  border-radius: 10px;
  border: 2px solid #151515;
}

/* Adjusting button size and style */
button {
  height: 30px;
  padding: 0 10px;
  font-size: 12px;
}

.solana-wallet-wrapper .wallet-adapter-button {
  background-color: #EC4899 !important;
  color: black !important;
  font-size: 16px !important;
  height: 44px !important;
  border-radius: 8px !important;
  width: 100% !important;
  justify-content: center !important;
  font-weight: 300 !important;
}

.solana-wallet-wrapper .wallet-adapter-button:hover {
  background-color: #D53F8C !important;
}


@media (max-width: 420px) {
  .home-box{
    margin-top:-109px;
  }
  .m-hide{
    display:none;
  }
  .m-show{
    display:block;
  }
}

@media (min-width: 420px) {
  .d-hide{
    display:none;
  }
  .d-show{
    display:block;
  }
}

@media (max-width: 640px) {
  .form-container {
    margin-top: -106px !important;
  }
}

@media (min-width: 641px) {
  .form-container {
    margin-top: 0;
  }
}